@import '../common-ui/sass/variables';

.galleryItemContainer {
  height: 110px;
  width: 100%;
  border-radius: 4px;
  background: #FAFAFA;
  box-shadow: 0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20);
  margin-bottom: 10px;
  display: grid;
  grid-template-columns: 3fr 2.4fr 2.4fr 2.4fr;
  white-space: nowrap;
  .img {
    margin-right: 3px;
    vertical-align: middle;
  }
  .font1 {
    font-size: 18px;
    font-weight: bold;
  }
  .font2 {
    font-size: 15px;
  }
  .font3 {
    font-weight: bold;
    font-size: 12.5px;
    color: $brand-dark;
  }
  .font4 {
    font-weight: bold;
    font-size: 12px;
    color: #BABABA;
  }
  .check {
    color: green;
  }
  .cross {
    color: rgb(223, 16, 16);
    font-weight: bold;
    font-size: 14px;
  }
  .noInfo {
    opacity: 0.7;
  }
  .disabled {
    opacity: 0.5;
  }
  .thumbnail {
    position: relative;
    grid-column: 1;
    grid-row: 1 / 4;
    margin-right: 10px;
    .img {
      width: 100%;
      height: 110px;
      object-fit: fill;
    }
    .note {
      width: 18px;
      height: 18px;

      position: absolute;
      left: 5px;
      bottom: 5px;
      border-radius: 5px;
      background: #FAFAFA;
      opacity: 0.7;
    }
  }
  .headline {
    grid-column: 2 / 5;
    grid-row: 1;
    padding-top: 10px;
    height: 26px;
  }
  .processing {
    grid-column: 2 / 5;
    grid-row: 2;
    padding-top: 10px;
  }
  .info1 {
    grid-column: 2;
    grid-row: 2;
  }
  .info2 {
    grid-column: 3;
    grid-row: 2;
  }
  .info3 {
    grid-column: 4;
    grid-row: 2;
  }
  .actionGrid {
    padding-top: 10px;
    grid-column: 2/5;
    grid-row: 3;
    .left {
      min-height: 10px;
      width: 60%;
      float: left;
    }
    .left2 {
      min-height: 10px;
      width: 55%;
      float: left;
    }
    .right {
      float: right;
      padding-right: 20px;
    }
  }
  .loader {
    height: 6px;
    width: 95%;
    border-radius: 4px;
    position: relative;
    overflow: hidden;
    background-color: #ddd;
  }
  .loader:before{
    display: block;
    position: absolute;
    content: "";
    left: -200px;
    width: 200px;
    height: 6px;
    background-color: $brand-dark;
    animation: loading 2s linear infinite;
  }
  
  @keyframes loading {
      from {left: -200px; width: 30%;}
      50% {width: 30%;}
      70% {width: 70%;}
      80% { left: 50%;}
      95% {left: 120%;}
      to {left: 100%;}
  }
}
