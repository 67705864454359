$brand-dark2: #0e5c6a;
$brand-dark: #288799;
$brand: #5db2c4;
$brand-light: #c2e8e3;
$brand-light2: #ebf6f8;
$black: rgba(0,0,0,.87);
$dark-gray: rgba(0,0,0,.53);
$gray: rgba(179,179,179, .87);
$light-gray: #ededed;
$light-gray2: #f5f5f5;
$light-gray3: #ccc;
$white: #fff;
$needs-action-background: #e9f3f5;
$handled-background: $white;

$computer-max-width: 414px;

$header-height: 60px;
$summary-height: 45px;
$ios-footer-height: 80px;

.m-auto {
  margin: auto;
}

.m-3 {
  margin: 1.5rem;
}

.m-2 {
  margin: 1rem;
}

.m-1 {
  margin: .5rem;
}

.mt-4 {
  margin-top: 2rem;
}

.mt-3 {
  margin-top: 1.5rem;
}

.mt-2 {
  margin-top: 1rem;
}

.mt-1 {
  margin-top: .5rem;
}

.mb-4 {
  margin-bottom: 2rem;
}

.mb-3 {
  margin-bottom: 1.5rem;
}

.mb-2 {
  margin-bottom: 1rem;
}

.mb-1 {
  margin-bottom: .5rem;
}

.ml-1 {
  margin-left: .5rem;
}

.ml-2 {
  margin-left: 1rem;
}

.ml-3 {
  margin-left: 1.5rem;
}

.mr-1 {
  margin-right: .5rem;
}

.mr-2 {
  margin-right: 1rem;
}

.mr-3 {
  margin-right: 1.5rem;
}

$zindex-normal: 0;
$zindex-popup: 50;
$zindex-top: 998;