@import '../common-ui/sass/variables';

#ErrorPage {
    position: fixed;
    width: 100%;
    height: 100%;
    background: white;
    
    display: flex;
    flex-direction: column;
    align-items: center;

    .error-page_top {
        position: relative;
        width: 200%;
        height:45%;
        background: #EBF6F8;
        border-bottom-left-radius:50%;
        border-bottom-right-radius:50%;

        display: flex;
        flex-direction: row;
        justify-content: center;

        .error-top_icon {
            margin: 30px 0 20px;
            max-width: 45%;
        }
    }

    @media only screen and (min-width: 1050px) {
        .error-page_top {
            width: 135%;
        }
    }

    .error-page_middle {
        margin-top: 50px;

        .description {
            font-family: Poppins;
            font-style: normal;
            font-weight: 500;
            font-size: 26px;
            line-height: 39px;
            color: #000000;
        }

        .buttons {
            margin-top: 35px;

            display: flex;
            justify-content: space-around;

            .button {
                background: #FFFFFF;

                border: 1px solid #E0E0E0;
                box-sizing: border-box;
                border-radius: 5px;
                font-size: 16px;
                line-height: 22px;
                color: #828282;
            }

            .button.primary {
                background: #288799;
                color: #FFFFFF;
            }
        }
    }

    .error-page_bottom {
        position: fixed;
        bottom: 10px;
        width: 100%;
        display: flex;
        justify-content: center;

        font-family: Avenir;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 25px;
    }
}