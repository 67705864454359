#EmailSignupContainer {
  label {
    position:relative;
  }
  
  .errorMsg {
    color: red;
  }

  span {
    pointer-events: none;
    position:absolute;
    left:0;
    top:0;
    transition: 0.2s;
    transition-timing-function: ease;
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    opacity:0.75;
    font-size: 18px;
  }

  input:focus + span, input:not(:placeholder-shown) + span {
    font-size: 12px;
    transform: translateY(-100%);
  }

  input {
    width: 100%;
    border: none;
    font-size: 18px;
    border-bottom: 1.5px solid #000000;
    margin-bottom: 30px;
  }

  input:focus, div:focus {
    outline: none;
  }

  .Checkbox {
    margin-left: 2px;
    float: left;
    margin-right: 20px;
    margin: 4px 20px 0 2px;
  }

  .radioDescription {
    font-size: 16px;
    font-weight: 400;
    a {
      color: #000000;
      font-weight: bold;
      text-decoration: underline;
    }
  }
}