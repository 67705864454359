@import '../common-ui/sass/variables';

.gallery-item-note {
  .header-text {
    font-family: Avenir;
    font-size: 20px;
    line-height: 28px;

    margin: 10px;
  }

  .botton-text {
    text-align: right;
    margin: 20px 10px 0 0;

    font-family: Avenir;
    font-size: 14px;
    line-height: 28px;
    color: #288799;
  }

  .botton-text div {
    display: inline-block;
    padding-left: 25px;
  }

  .note-input {
    height: 170px;
    margin: 10px;
    width: calc(100% - 20px);

    background: rgba(40, 135, 153, 0.1);
    border-radius: 4px 4px 0px 0px;
    border: 0;
    border-bottom: 2px solid #288799;
    
    font-family: Avenir;
    font-size: 16px;
    line-height: 22px;

    color: #333333;
  }
}
