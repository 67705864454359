@import '../common-ui/sass/variables';

#KontolinkSignupContainer {
  height: 100%;
  width: 100%;
  background-color: $brand-dark;
  position: fixed;

  .errorMsg {
    color: rgb(255, 244, 147);
  }

  .mainContainer {
    margin-top: 10%;
    height: calc(75% - 40px);
  }

  .kontolinklogo {
    text-align: center;
    p {
      font-size: 22px;
      color: #FFF;
      margin: 5px 0 40px;
    }
  }

  label {
    position:relative;
  }
    
  span {
    color: #fff;
    pointer-events: none;
    position:absolute;
    left:0;
    top:0;
    transition: 0.2s;
    transition-timing-function: ease;
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    opacity:0.75;
    font-size: 18px;
  }

  input[type=email]:focus + span, input[type=email]:not(:placeholder-shown) + span {
    font-size: 12px;
    transform: translateY(-100%);
  }

  input[type=email] {
    color: #fff;
    background-color: $brand-dark;
    width: 100%;
    border: none;
    font-size: 18px;
    border-bottom: 1.5px solid #fff;
    margin-bottom: 25px;
  }

  input:focus, div:focus {
    outline: none;
  }

  .nxtButton {
    float: right;
    width: 100px;
    height: 20px;
    margin-top: -12px;
    border-radius: 20px;
    line-height: 19px;
    padding: 10px 0;
    background-color: #FFF;
    font-weight: bold;
    text-align: center;
    color: #197283;
    z-index: 999;
    &.disabled {
      opacity: 0.5;
    }
  }

  .footNote {
    width: 100%;
    font-size: 12px;
    color: #fff;
    opacity: 0.75;
    padding: 10px 0;
    box-sizing: border-box;
  }
}