@import '../common-ui/sass/variables';

.form {
  &__radio-label, &__checkbox-label {
    cursor: pointer;
    font-size: 2rem;
    position: relative;
  }

  &__radio-button {
    height: 2rem;
    width: 2rem;
    border: 3px solid $brand-dark;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    left: 5px;
    top: 4px;
  
    &::after {
      content: '';
      display: block;
      width: .8rem;
      height: .8rem;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 48%;
      transform: translate(-50%, -50%);
      background-color: $brand-dark;
      opacity: 0;
      transition: opacity .3s;
    }
  }

  &__radio-label, &__checkbox-label {
    padding-left: 1.5rem;
  }
  
  &__radio-input, &__checkbox-input {
    display: none;
    left: 30px;
  }

  &__radio-input:checked ~ &__radio-label &__radio-button::after {
    opacity: 1;
  }

  &__checkbox-button {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    height: 2.5rem;
    width: 2.5rem;
    background-color: $light-gray;

    &::after {
      content: '';
      transition: all .3s;
      display:none;
      opacity: 0;
      margin-left: .8rem;
      width: 1rem;
      height: 2rem;
      border: solid $brand;
      border-width: 0 .4rem .3rem 0;
      transform: rotate(45deg);      
    }
  }

  &__checkbox-label input:checked ~ &__checkbox-button::after {
    opacity: 1;
    display: block; 
  }
}

.shakeInput {
  -webkit-animation: kf_shake 0.4s 1 linear;
  -moz-animation: kf_shake 0.4s 1 linear;
  -o-animation: kf_shake 0.4s 1 linear;
}
@-webkit-keyframes kf_shake {
  0% { -webkit-transform: translate(30px); }
  20% { -webkit-transform: translate(-30px); }
  40% { -webkit-transform: translate(15px); }
  60% { -webkit-transform: translate(-15px); }
  80% { -webkit-transform: translate(8px); }
  100% { -webkit-transform: translate(0px); }
}
@-moz-keyframes kf_shake {
  0% { -moz-transform: translate(30px); }
  20% { -moz-transform: translate(-30px); }
  40% { -moz-transform: translate(15px); }
  60% { -moz-transform: translate(-15px); }
  80% { -moz-transform: translate(8px); }
  100% { -moz-transform: translate(0px); }
}
@-o-keyframes kf_shake {
  0% { -o-transform: translate(30px); }
  20% { -o-transform: translate(-30px); }
  40% { -o-transform: translate(15px); }
  60% { -o-transform: translate(-15px); }
  80% { -o-transform: translate(8px); }
  100% { -o-origin-transform: translate(0px); }
}

.menu-content {
  color: #000;
  a {
    color: #000;
    text-decoration: none;
  }
  .close {
    position: absolute;
    top: -10px;
    right:10px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    font-size: 45px;
  }
  .logo {
    padding: 25px 10px 15px;
    font-size: 15px;
    font-weight: bold;
    display: flex;
    align-items: center;
    .name, .special {
      font-size: 22px;
      color: #000;
      font-weight: 500;
    }
    .special {
      font-weight: bolder;
      color: $brand-dark2;
    }
    img {
      margin-right: 10px;
      height: 32px;
    }
  }
  .item {
    font-size: 16px;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
  }
  .user {
    font-size: 14px;
    position: absolute;
    bottom: 10px;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    overflow-x: hidden;
    text-align: center;
    opacity: 0.75;
  }
}

.closePopup {
  position: absolute;
  z-index: $zindex-top + 1;
  top: -10px;
  right:10px;
  cursor: pointer;
  padding: 0;
  color: #000;
  font-size: 55px;
}

#pin {
  text-align: center;
  margin-bottom: 30px;
  input {
    background-color: #FFF;
    opacity: 0.6;
    color: $brand-dark;
    caret-color: transparent;
    height: 46px;
    width: 46px;
    border-radius: 46px;
    box-sizing: border-box;
    margin-left: 10px;
    font-size: 32px;
    font-weight: bold;
    text-align: center;
    border: none;
    transition: color 1s, font-size 1s, height 1s, width 1s;
  }
  input:valid {
    height: 20px;
    width: 20px;
    font-size: 16px;
    opacity: 1;
    color: transparent;
  }
  input:focus {
    opacity: 1;
    border: 2px solid #0E5C69;
    height: 46px;
    width: 46px;
    outline: none;
  }
  
}