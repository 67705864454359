@import '../common-ui/sass/variables';

#onboardingContainer {
  height: 100%;
  width: 100%;
  text-align: center;
  position: fixed;

  .landingPage {
    height: 100%;
    background: linear-gradient(0deg,rgba(255,255,255,0.4), rgba(255, 255, 255, .4)), url("../media/landing-page.jpg");
    background-size: cover;
    background-position-y: 20px;
  }
  .discontinueOverlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    min-width: 250px;
    border: 4px black solid;
    border-radius: 10px;
    background-color: $brand-dark;
    color: $brand-light2;
    padding: 10px;
    .snapLink {
      color: white;
      font-weight: bold;
      text-decoration: underline;
    }
  }

  .button {
    margin: auto;
    width: 260px;
    height: 24px;
    border-radius: 25px;
    margin-top: 12px;
    padding: 12px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      height: 22px;
      margin-right: 5px;
    }
    &.blue {
      color: white;
      background-color: $brand-dark;
      border: 1px solid $brand-dark;
    }
    &.disabled {
      color: white;
      background-color: $brand-dark2;
      border: 1px solid $brand-dark;
      opacity: 0.5;
    }
    &.transparent {
      border: 1px solid black;
      text-shadow: white 0px 0px 10px;
    }
  }

  .header {
    background-color: white;
    text-align: center;
    height:50px;
    width: 100%;
    font-size: 15px;
    font-weight: bold;
    box-sizing: border-box;
    box-shadow: 0 3px 3px -2px rgba(0, 0, 0, .5);
    display: flex;
    justify-content: space-between;
    .backButton {
      height: 24px;
      width: 24px;
      padding: 13px;
    }
    .brand {
      display: flex;
      align-items: center;
      .name, .special {
        font-size: 18px;
        color: #000;
        font-weight: 500;
      }
      .special {
        font-weight: bolder;
        color: $brand-dark2;
      }
      img {
        height: 24px;
        margin-right: 10px;
      }
    }
  }

  .headLine {
    font-size: 26px;
    font-weight: 700;
    margin-top: 0;
    padding: 18px 0;
    font-size: 26px;
    text-shadow: white 0px 0px 10px;
  }

  .description {
    font-size: 14px;
    padding: 0 24px;
    font-weight: 700;
    text-shadow: white 0px 0px 10px;
  }
  
  .mainContainer {
    text-align: left;
    padding: 0 30px;
    margin-top: 15vh;
  }

  .bottomContainer {
    position: fixed;
    width: 100%;
    bottom: 0;
    font-size: 18px;
    font-weight: 500;
    padding: 20px 0;
    box-sizing: border-box;
  }
  
}