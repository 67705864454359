@import '../common-ui/sass/variables';

#captureContainer {
  .helpText {
    padding: 20px;
    font-size: 16px;
  }
  .videoContainer {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%; 
    overflow: hidden;
    video {
      /* Make video to at least 100% wide and tall */
      width: 100%; 
      height: 100%;
      object-fit: fill;

      /* Center the video */
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }
  }
  .discontinueOverlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    min-width: 250px;
    border: 4px black solid;
    border-radius: 10px;
    background-color: $brand-dark;
    color: $brand-light2;
    padding: 10px;
    .snapLink {
      color: white;
      font-weight: bold;
      text-decoration: underline;
    }
  }
  .captureBtn {
    display: inline-block;
    position: fixed;
    bottom: 2%;
    left: calc(50% - 40px);
    text-decoration: none;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    text-align: center;
    background: #f7f7f7;
    box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.08);
  }

  .captureBtn .fa {
    position: absolute;
    content: '';
    width: 64px;
    height: 64px;
    line-height: 64px;
    vertical-align: middle; 
    left: 8px;
    top: 7px;
    border-radius: 50%;
    background-image: -webkit-linear-gradient(#e8e8e8 0%, #d6d6d6 100%);
    background-image: linear-gradient(#e8e8e8 0%, #d6d6d6 100%);
    text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.66);
    box-shadow: inset 0 2px 0 rgba(255,255,255,0.5), 0 2px 2px rgba(0, 0, 0, 0.19);
    border-bottom: solid 2px #b5b5b5;
    }
    
  .captureBtn .fa:active {
    background-image: -webkit-linear-gradient(#efefef 0%, #d6d6d6 100%);
    box-shadow: inset 0 1px 0 rgba(255,255,255,0.5), 0 2px 2px rgba(0, 0, 0, 0.19);
    border-bottom: solid 2px #d8d8d8;
  }

  .galleryBtn {
    position: fixed;
    bottom: calc(2% + 8px);
    right: 8%;
  }
}