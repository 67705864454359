@import '../common-ui/sass/variables';

#galleryPage {
  .cameraBtn {
    position: fixed;
    bottom: 20px;
    right: 5%;
    width: 64px;
    height: 64px;
    border-radius: 32px;
    background-color: $brand-dark;
    box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.20);
    img {
      width: 30px;
      height: 30px;
      position: absolute;
      left: 17px;
      top: 17px;
    }
  }
  .discontinueOverlay {
    margin: 5px;
    min-width: 250px;
    border: 4px black solid;
    border-radius: 10px;
    background-color: $brand-dark;
    color: $brand-light2;
    padding: 10px;
    .snapLink {
      color: white;
      font-weight: bold;
      text-decoration: underline;
    }
  }
  .galleryHeader {
    background-color: $brand-dark;
    color: #FFF;
    text-align: center;
    font-size: 15px;
    font-weight: bold;
    height:50px;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    box-shadow: 0 3px 6px -2px rgba(0, 0, 0, .5);
    display: flex;
    justify-content: space-between;
    .menuBtn {
      height: 20px;
      width: 20px;
      padding: 15px;
    }
    .brand {
      display: flex;
      align-items: center;
      .name, .special {
        font-size: 18px;
        color: #fff;
        font-weight: 500;
      }
      .special {
        font-weight: bolder;
      }
      img {
        height: 24px;
        margin-right: 10px;
      }
    }
  }
  .galleryContainer {
    width: 100%;
    min-height: 100vh;
    box-sizing: border-box;
    padding: 10px;
    background-color: #F5F5F5;
    .description {
      width: 100%;
      text-align: right;
      font-size: 14px;
      height: 20px;
      margin-bottom: 5px;
    }
    .day {
      width: 100%;
      font-size: 12px;
      font-weight: bold;
      margin-bottom: 5px;
    }
  }
}