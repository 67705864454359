@import '../common-ui/sass/variables';

#imagePage {
  height: 100%;
  width: 100%;
  position: fixed;
  overflow: auto;

  .imageHeader {
    background-color: $brand-dark;
    color: #FFF;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    height:50px;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    box-shadow: 0 3px 6px -2px rgba(0, 0, 0, .5);
    .backButton {
      float: left;
      height: 24px;
      width: 24px;
      padding: 13px;
    }
    .text {
      padding-top: 13px;
      float: left;
    }
  }
  .imageContainer {
    position: relative;
    height: 70%;
    img {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
  }
  .info {
    position: relative;
    bottom: 0;
    display: grid;
    grid-template-columns: 3.3fr 3.3fr 3.3fr;
    white-space: nowrap;
    font-size: 15px;
    img {
      margin-right: 3px;
    }
    .headline {
      font-size: 18px;
      font-weight: bold;
      grid-column: 1 / 4;
      grid-row: 1;
      padding: 20px;
    }
    .processing {
      grid-column: 1 / 4;
      grid-row: 2;
      padding: 0 20px;
    }
    .info1 {
      grid-column: 1;
      grid-row: 2;
      padding-left: 20px;
    }
    .info2 {
      grid-column: 2;
      grid-row: 2;
    }
    .info3 {
      grid-column: 3;
      grid-row: 2;
      margin-left: -20px;
    }
    .info4 {
      grid-column: 1 / 4;
      grid-row: 2;
      padding-left: 20px;
    }
    .check {
      color: green;
    }
    .cross {
      color: rgb(223, 16, 16);
      font-weight: bold;
      font-size: 14px;
    }
  }
  .loader {
    height: 6px;
    width: 95%;
    border-radius: 4px;
    position: relative;
    overflow: hidden;
    background-color: #ddd;
  }
  .loader:before{
    display: block;
    position: absolute;
    content: "";
    left: -200px;
    width: 200px;
    height: 6px;
    background-color: $brand-dark;
    animation: loading 2s linear infinite;
  }

  .note-header-text {
    font-family: Avenir;
    font-size: 20px;
    line-height: 28px;

    margin-top: 10px;
  }

  .note-content {
    font-family: Avenir;
    font-size: 16px;
    line-height: 22px;
    margin-top: 10px;

    color: #333333;
  }
  
  @keyframes loading {
      from {left: -200px; width: 30%;}
      50% {width: 30%;}
      70% {width: 70%;}
      80% { left: 50%;}
      95% {left: 120%;}
      to {left: 100%;}
  }
}